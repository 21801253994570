export enum SignalWallId {
  盤中突破日內高點 = 'RT_futuresai_01',
  盤中突破日內低點 = 'RT_futuresai_02',
  盤中高預估周轉率 = 'RT_futuresai_03',
  盤中高預估量比 = 'RT_futuresai_04',

  多方選股 = 'ED_m168168_01',
  空方選股 = 'ED_m168168_02',
  短線軋空 = 'ED_m168168_03',
  撿便宜 = 'ED_m168168_04',
  MVP多方 = 'RT_mvp888_stock_01',
  MVP空方 = 'RT_mvp888_stock_02',
  大單成交 = 'qty_alert',
  // low_price_qty_alert
  // middle_price_qty_alert
  // high_price_qty_alert
  // super_high_price_qty_alert

  /** South */
  SOUTH_1 = 'RT_south_01',
  SOUTH_2 = 'RT_south_02',
  SOUTH_3 = 'RT_south_03',
  SOUTH_4 = 'RT_south_04',
  SOUTH_5 = 'RT_south_05',
  SOUTH_6 = 'RT_south_06',
  SOUTH_7 = 'RT_south_07',
  SOUTH_8 = 'RT_south_08',
  SOUTH_9 = 'RT_south_09',
  SOUTH_10 = 'RT_south_10',
  SOUTH_11 = 'RT_south_11',

  /** Gorich55688_stock */
  gorich55688_強勢整理股 = 'ED_gorich55688_01',
  gorich55688_籌碼優勢股 = 'ED_gorich55688_02',
}
